import React from "react"
import marked from "marked"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"

//scss
import "../scss/modules/info-page.scss"
import "../scss/modules/button-component.scss"
import "../components/layout.css"
import FloatingBtn from "../components/floating-btn"

const IndexPage = data => (
  <Layout>
    <div className={"info-page"}>
      <Seo
        title={data.data.allStrapiInfoPratique.nodes[0].SEO_titre}
        description={data.data.allStrapiInfoPratique.nodes[0].SEO_description}
      />
      <div className={"info-component d-flex justify-content-center"}>
        <div className={"row col-lg-10 d-flex"}>
          <div className={"wrapper-content"}>
            <div className={"main-content"}>
              <h1 className={"subtitle"}>
                CENTRE D’IMAGERIE MÉDICALE DE L'HÔPITAL CLAUDE GALIEN
              </h1>

              <div className={"contact-btn d-flex"}>
                <a
                  className={"button-component"}
                  target="_blank"
                  rel="noreferrer"
                  href="mailto:secretariat@imagerieclaudegalien.com"
                >
                  <button><FontAwesomeIcon icon={faEnvelope}/>  Contactez-nous</button>
                </a>
              </div>

                <div className={"d-flex flex-row"}>
                  <div className={"col-md-12 info-banner"}>
                    <iframe
                      src={data.data.allStrapiInfoPratique.nodes[0].LienMaps}
                      width="550"
                      height="400"
                      frameBorder="0"
                      aria-hidden="false"
                      title="maps"
                    />
                    <img src={"https://admin.radiologieclaudegalien.fr"+data.data.allStrapiInfoPratique.nodes[0].photo[0].url}/>
                  </div>
                </div>

                <div className={"info-block d-flex"}>
                  <div className={"col-lg-6 col-sm-12 before-block "}>
                    <h2 className={"subtitle text-uppercase"}>Horaires</h2>
                    {data.data.allStrapiInfoPratique.nodes[0].gauche.map((x, index) => (
                      <div className={"item"} key={index}>
                        <div dangerouslySetInnerHTML={{ __html: marked(x.champs) }}></div>
                        <span className={"away"}></span>
                      </div>
                    ))}
                  </div>

                  <div className={"col-lg-6 col-sm-12"}>
                    <h2 className={"subtitle text-uppercase"}>Accès</h2>
                    <div className={"address-block before-block"}>
                      <div className={"info-address col-md-8"}>
                        {data.data.allStrapiInfoPratique.nodes[0].hopital.map(
                          (x, index) => (
                            <div className={"item pad"} key={index}>
                              <div
                                dangerouslySetInnerHTML={{ __html: marked(x.champs) }}
                              ></div>
                              <span className={"away"}></span>
                            </div>
                          )
                        )}
                      </div>
                      <div className={"content-btn col-md-4"}>
                        <a
                          className={"button-component"}
                          target="_blank"
                          rel="noreferrer"
                          href={data.data.allStrapiInfoPratique.nodes[0].LienItineraire}
                        >
                          <button>Voir l'Itinéraire</button>
                        </a>
                      </div>
                    </div>
                    {data.data.allStrapiInfoPratique.nodes[0].acces.map((x, index) => (
                      <div className={"item"} key={index}>
                        <div
                          dangerouslySetInnerHTML={{ __html: marked(x.champs) }}
                        ></div>
                        <span className={"away"}></span>
                      </div>
                    ))}
                  </div>
                </div>
            </div>
          </div>

          <div className={"circles"}>
            <span className={"circlesOne"}></span>
            <span className={"circlesTwo"}></span>
            <span className={"circlesThird"}></span>
            <span className={"circlesFourth"}></span>
            <span className={"circlesFifth"}></span>
          </div>
        </div>
      </div>
      <FloatingBtn/>
    </div>
  </Layout>
)

export default IndexPage

export const query = graphql`
  query QueryInfoPratique {
    allStrapiInfoPratique {
      nodes {
        hopital {
          champs
        }
        gauche {
          champs
        }
        acces {
          champs
        }
        LienItineraire
        LienMaps
        SEO_description
        SEO_titre
        photo{
          url
        }
      }
    }
    allStrapiUrl {
      nodes {
        ResultatExamen
        PrendreRendeVous
      }
    }
  }
`
